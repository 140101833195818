import { stringIsNullOrEmpty } from './util';

export class ConfigEnum {
    static _LANGUAGE = "language";
    static _PREFERENCE_LANGUAGE = "preferenceLanguage";
    static _BEARER_TOKEN = "token";
    static _USER_DATA = "userData";
    static _FCM_TOKEN = "fcmToken";
    static _IS_FIRST_LAUNCH = "isFirstLaunch";
    static _REFERRAL_CODE = "referralCode";
    static _UPLINE_REFERRAL_CODE = "uplineReferralCode";
    static _BIOMETRIC_SETUP_BY = "biometricSetupBy";
    static _BIOMETRIC_ACKNOWLEDGE = "biometricAcknowledge"; // first time biometric popup
    static _SPIN_IS_MUTED = "spinIsMuted";
    static _EVENT_RANKING = "eventRanking"; // kog event ranking list
    static _USER_EVENT_RANKING = "userEventRanking"; // user kog event ranking
    static _ADS_ID = "adsId";
    static _HOME_BANNER_LAST_RETRIEVED = "homeBannerLastRetrieved";
    static _HOME_RANKING_LAST_RETRIEVED = "homeRankingLastRetrieved";
    static _LAST_CHECK_BONUS_DATETIME = "lastCheckBonusDateTime";
    static _DEVICE_ID = "deviceId";
}

var _config = {};
_config[ConfigEnum._LANGUAGE] = "en";
_config[ConfigEnum._PREFERENCE_LANGUAGE] = "";
_config[ConfigEnum._BEARER_TOKEN] = "";
_config[ConfigEnum._USER_DATA] = {};
_config[ConfigEnum._FCM_TOKEN] = "";
_config[ConfigEnum._IS_FIRST_LAUNCH] = "";
_config[ConfigEnum._REFERRAL_CODE] = "";
_config[ConfigEnum._UPLINE_REFERRAL_CODE] = "";
_config[ConfigEnum._BIOMETRIC_SETUP_BY] = "";
_config[ConfigEnum._BIOMETRIC_ACKNOWLEDGE] = {};
_config[ConfigEnum._SPIN_IS_MUTED] = false;
_config[ConfigEnum._EVENT_RANKING] = "";
_config[ConfigEnum._USER_EVENT_RANKING] = "";
_config[ConfigEnum._ADS_ID] = "";
_config[ConfigEnum._HOME_BANNER_LAST_RETRIEVED] = null;
_config[ConfigEnum._LAST_CHECK_BONUS_DATETIME] = "";
_config[ConfigEnum._DEVICE_ID] = "";

/// <summary>
/// Author : Saitama
/// </summary>
export class Config {
    static _instance = null;

    /// <summary>
    /// Author : Saitama
    /// </summary>
    static getInstance() {
        if (stringIsNullOrEmpty(Config._instance)) {
            Config._instance = new Config();
            Config._instance.readData();
        }

        return Config._instance;
    }

    /// <summary>
    /// Author : Saitama
    /// </summary>
    getValue(key) {
        return _config[key];
    }

    /// <summary>
    /// Author : Saitama
    /// </summary>
    getJson(key) {
        return JSON.parse(_config[key]);
    }

    /// <summary>
    /// Author : Saitama
    /// </summary>
    getCustomJsonObj(configKey, selectedKeys) {
        let configObj = _config[configKey];

        let customObj = {};
        selectedKeys.map((key) => {
            customObj[key] = configObj[key];
        });

        return customObj;
    }

    /// <summary>
    /// Author : Saitama
    /// </summary>
    setData(key, value) {
        if (_config.hasOwnProperty(key)) {
            _config[key] = value;
            if (typeof value === 'object') {
                localStorage.setItem(key, JSON.stringify(value));
            }
            else {
                localStorage.setItem(key, value);
            }
        }
    }

    /// <summary>
    /// Author : Saitama
    /// </summary>
    saveData() {
        for (const [key, value] of Object.entries(_config)) {
            localStorage.setItem(key, JSON.stringify(value));
        }
    }

    /// <summary>
    /// Author : Saitama
    /// </summary>
    readData() {
        for (const [key, value] of Object.entries(_config)) {
            const storageValue = localStorage.getItem(key);
            if (!stringIsNullOrEmpty(storageValue)) {
                if (typeof storageValue === 'object') {
                    _config[key] = JSON.parse(storageValue);
                }
                else {
                    _config[key] = storageValue;
                }
            }
        }
    }
}