import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { _STANDARD_MOBILE_VIEW_WIDTH_SIZE, BG_TYPE, Language, ApiKey, _LANGUAGE_OPTION, ScreenWidth } from "./common/constant";
import { stringIsNullOrEmpty, isObjectEmpty, checkBrowser } from "./common/util";
import Loader from "./component/loader";
import Content from "./routes/content";
import { connect, useDispatch } from "react-redux";
import { useRouter } from "./hooks/useRouter";
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { _NON_AUTH_ROUTES, _AUTH_ROUTES, Pages } from './routes/page';
import { PageSettings } from './routes/setting';
import CustomDialog from './component/dialog';
import { showCustomDialog, setTempCustomDialogData, checkInSpinLogin } from './application/action/app_action';
import { ToastContainer } from 'react-toastify';
import { getMemberDetails } from './application/action/auth_action';
import { ConfigEnum } from './common/config';
import { Image } from '@chakra-ui/react';
import Routes, { _RESOURCE_URL } from './common/api_routes';
import ApiEngine from './common/api_engine';
import { Fab, Action } from 'react-tiny-fab';
import { isMobile, isBrowser } from 'react-device-detect';
import moment from 'moment';
import { Carousel } from 'react-responsive-carousel';
import { toast } from 'react-toastify';
import Header from './pages/non-auth-pages/header';

/// <summary>
/// Author: Saitama
/// </summary>
const App = () => {
    const { t, i18n } = useTranslation();
    var _router = useRouter();
    var _location = useLocation();
    var _dispatch = useDispatch();
    var _customDialogData = useSelector((state) => state.appState.customDialogData);
    var _tempCustomDialogData = useSelector((state) => state.appState.tempCustomDialogData);
    const _IS_LOGGED_IN = useSelector((state) => state.authState.isLoggedIn);
    const _LAST_CHECK_IN = useSelector((state) => state.appState.spinLoginCheckedDate);
    const [bgType, setBgType] = useState(BG_TYPE.blur);
    const [customerSupport, setCustomerSupport] = useState([]);
    const [isAppInit, setIsAppInit] = useState(false);
    const withoutHeaderPaths = [Pages._DEFAULT, Pages._WEB_VIEW, Pages._SPIN_EVENT];
    const withHeaderPaths = [Pages._REGISTER, Pages._LOGIN, Pages._FORGOT_PASSWORD, Pages._CREATE_OR_UPDATE_BANK, Pages._SECONDARY_PASSWORD_LOGIN];

    /// <summary>
    /// Author: Saitama
    /// </summary>
    useEffect(() => {
        (async () => {

            if (checkBrowser()) {
                window.scrollTo(0, 0);
            }

            if (stringIsNullOrEmpty(localStorage.getItem(ConfigEnum._LANGUAGE))) {
                localStorage.setItem(ConfigEnum._LANGUAGE, Language._ENGLISH);
            }

            if (!_IS_LOGGED_IN) {
                let csArr = [];
                var responseJson = await ApiEngine.get(Routes._GET_CUSTOMER_SERVICE_WITHOUT_LOGIN, { headers: { "skipLoading": true } });


                if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                    let csData = responseJson[ApiKey._API_DATA_KEY];

                    if (!stringIsNullOrEmpty(csData)) {
                        if (csData.includes(',')) {
                            csArr = csData.split(',');
                        }
                        else {
                            csArr.push(csData);
                        }

                        setCustomerSupport(csArr.map((item, index) => {
                            return {
                                text: `${t('CUSTOMER_SERVICE')} ${index + 1}`,
                                icon: require('./assets/images/female-telemarketer.png'),
                                name: item
                            }
                        }));
                    }
                }
            }

            setIsAppInit(true);
        })();
    }, []);

    /// <summary>
    /// Author : Saitama
    /// </summary>
    useEffect(() => {
        (async () => {
            if (isAppInit && _IS_LOGGED_IN) {
                let today = moment().format('L');
                let lastRetrievedBannerTime = localStorage.getItem(ConfigEnum._HOME_BANNER_LAST_RETRIEVED);
                let lastRetrievedRankingTime = localStorage.getItem(ConfigEnum._HOME_RANKING_LAST_RETRIEVED);

                if (stringIsNullOrEmpty(lastRetrievedBannerTime) ||
                    lastRetrievedBannerTime != today) {
                    let bannerPopUp = [];
                    var responseJson = await ApiEngine.get(Routes._HOMESCREEN_BANNERS);


                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        responseJson[ApiKey._API_DATA_KEY]?.data?.map(item =>
                            bannerPopUp.push({
                                image: _RESOURCE_URL + item.imageLocalizations.find(i => i.languageId)?.value,
                                cta: item?.cta
                            }));

                        localStorage.setItem(ConfigEnum._HOME_BANNER_LAST_RETRIEVED, today);

                        if (bannerPopUp.length > 0) {
                            _dispatch(showCustomDialog({
                                hideTitle: true,
                                isPlainTheme: true,
                                content: '',
                                customContent: (
                                    <Carousel showArrows={false} showThumbs={false} showStatus={false} interval={3000} infiniteLoop autoPlay emulateTouch>
                                        {
                                            bannerPopUp.map((item, index) => {
                                                return (
                                                    <div key={index}>
                                                        <Image src={item.image} />
                                                    </div>
                                                )
                                            })
                                        }
                                    </Carousel>
                                )
                            }));
                        }
                    }
                }

                if (stringIsNullOrEmpty(lastRetrievedRankingTime) ||
                    lastRetrievedRankingTime != today) {
                    var levelUpDownResponse = await ApiEngine.get(Routes._CHECK_IS_LOGGED_IN);

                    if (levelUpDownResponse[ApiKey._API_SUCCESS_KEY] && levelUpDownResponse?.data != null && levelUpDownResponse?.data?.notification !== null) {
                        localStorage.setItem(ConfigEnum._HOME_RANKING_LAST_RETRIEVED, today);

                        let inactivityAlert =
                            levelUpDownResponse?.data?.inactivityAlertDetail;
                        let rankUpDownContent = levelUpDownResponse?.data?.notification?.content;

                        if (inactivityAlert?.dayLeft > 0) {
                            toast(t('DROP_RANK_IN', { days: inactivityAlert?.dayLeft }), {
                                type: 'warning',
                                position: "bottom-center",
                                autoClose: 5000,
                                closeOnClick: true,
                                theme: 'dark'
                            });
                        }

                        if (!stringIsNullOrEmpty(rankUpDownContent)) {
                            toast(rankUpDownContent, {
                                type: 'error',
                                position: "bottom-center",
                                autoClose: 5000,
                                closeOnClick: true,
                                theme: 'dark'
                            });
                        }

                    }
                }

                if (stringIsNullOrEmpty(_LAST_CHECK_IN) || _LAST_CHECK_IN != today) {
                    try {
                        _dispatch(checkInSpinLogin());
                    }
                    catch (err) {
                        // do nothing
                    }
                }
            }
        })();
    }, [isAppInit, _IS_LOGGED_IN])

    /// <summary>
    /// Author : Saitama
    /// </summary>
    useEffect(() => {
        if (
            isObjectEmpty(_customDialogData) &&
            !isObjectEmpty(_tempCustomDialogData)
        ) {
            _dispatch(showCustomDialog(_tempCustomDialogData));
            _dispatch(setTempCustomDialogData({}));
        }
    }, [_customDialogData, _tempCustomDialogData]);

    /// <summary>
    /// Author: Saitama
    /// </summary>
    function toggleBg(value) {
        setBgType(value);
    }

    return (
        <PageSettings.Provider value={{ toggleBg }}>
            <Loader />
            <CustomDialog
                isVisible={!isObjectEmpty(_customDialogData)}
                success={_customDialogData.success}
                headerTitle={_customDialogData.headerTitle}
                title={_customDialogData.title}
                content={_customDialogData.content}
                customContent={_customDialogData.customContent}
                onConfirm={_customDialogData.onConfirm}
                onCancel={_customDialogData.onCancel}
                confirmTxt={_customDialogData.confirmTxt}
                headerIcon={_customDialogData.headerIcon}
                cancelTxt={_customDialogData.cancelTxt}
                horizontalBtn={_customDialogData.horizontalBtn}
                disableHardwareBackPress={
                    _customDialogData.disableHardwareBackPress
                }
                isPlainTheme={_customDialogData.isPlainTheme}
                hideTitle={_customDialogData.hideTitle}
                hideFooter={_customDialogData.hideFooter}
            />
            <ToastContainer style={{ zIndex: 9999999 }} />

            {!checkBrowser() &&
                <div className={`app ${bgType == BG_TYPE.clear ? 'bg-clear' : bgType == BG_TYPE.black ? 'bg-black' : bgType == BG_TYPE.swc ? 'bg-mini-program-swc' : ''}`}>
                    <Content />
                </div>
            }


            {checkBrowser() &&
                // <div className={`desktop-app ${_location.pathname == Pages._LOGIN || _location.pathname == Pages._REGISTER || _location.pathname == Pages._FORGOT_PASSWORD ? 'bg-login' : ''}`}>
                <div className={`desktop-app ${!_IS_LOGGED_IN ? 'bg-login' : ''}`}>
                    {_IS_LOGGED_IN && !withoutHeaderPaths.includes(_location.pathname) && <Header />}
                    {!_IS_LOGGED_IN && withHeaderPaths.includes(_location.pathname) && <Header />}
                    <div style={_IS_LOGGED_IN && _location.pathname == Pages._GAMES && _location.pathname != Pages._WEB_VIEW ? { marginTop: "7rem" } : _IS_LOGGED_IN ? { marginTop: "12rem" } : {}}>
                        <Content />
                    </div>
                </div>
            }

            {
                !_IS_LOGGED_IN && customerSupport.length > 0 && !checkBrowser() &&
                <Fab
                    style={{ bottom: 10, right: -5 }}
                    mainButtonStyles={{ backgroundColor: '#0d39a8', padding: '3px' }}
                    icon={<Image src={require('./assets/images/customer-support.png')} />}
                    alwaysShowTitle={true}
                >
                    {
                        customerSupport.map((item, index) => {
                            return (
                                <Action
                                    key={index}
                                    text={item.text}
                                    style={{ backgroundColor: '#0d39a8', padding: '3px' }}
                                    onClick={() => {
                                        const newWindow = window.open(item.name, '_blank');
                                        if (!newWindow || newWindow.closed || typeof newWindow.closed === 'undefined') {
                                            alert('Pop-up blocked. Please allow pop-ups for this site.');
                                        }
                                    }}
                                >
                                    <Image src={item.icon} />
                                </Action>
                            )
                        })
                    }
                </Fab>
            }
            {
                !_IS_LOGGED_IN && isAppInit && _location?.pathname != Pages._DEFAULT && !checkBrowser() &&
                <Fab
                    style={{ bottom: 10, left: -5 }}
                    mainButtonStyles={{ backgroundColor: '#0d39a8', padding: '3px' }}
                    icon={<Image src={require(`./assets/images/${localStorage.getItem(ConfigEnum._LANGUAGE)}.png`)} />}
                    alwaysShowTitle={true}
                >
                    {
                        _LANGUAGE_OPTION.map((item, index) => {
                            return (
                                <Action
                                    key={index}
                                    text={item.label}
                                    style={{ backgroundColor: '#0d39a8', padding: '3px' }}
                                    onClick={() => {
                                        i18n.changeLanguage(item.value);
                                        localStorage.setItem(ConfigEnum._LANGUAGE, item.value);
                                    }}
                                >
                                    <Image src={require(`./assets/images/${item.value}.png`)} />
                                </Action>
                            )
                        })
                    }
                </Fab>
            }
        </PageSettings.Provider>
    );
};

const mapStateToProps = state => ({ ...state });

export default connect(mapStateToProps, null)(App);