import {
    ApiKey,
    _WEB_VERSION,
    _APP_CLIENT_ID,
    _APP_CLIENT_SECRET,
    _MEMBER_ROLE,
    _LOGIN_METHOD,
    SessionKey
} from '../../common/constant';
import Routes from '../../common/api_routes';
import { showCustomDialog } from './app_action';
import ApiEngine from '../../common/api_engine';
import i18n from '../../localization/i18n';
import { ConfigEnum } from '../../common/config';
import {
    createFormBody,
    isObjectEmpty,
    stringIsNullOrEmpty,
    getUserCurrentLocation
} from '../../common/util';
import { Pages } from '../../routes/page';
import axios from 'axios';
import { AuthActionTypes } from '../reducer/auth_reducer';
import { v1 as uuidv1 } from 'uuid';

/// <summary>
/// Author : Saitama
/// </summary>
export function performLogin(
    phone,
    password,
    secondaryPassword = ''
) {
    return async (dispatch, getState) => {
        const location = await getUserCurrentLocation();
        let _device_id = localStorage.getItem(ConfigEnum._DEVICE_ID);

        if (stringIsNullOrEmpty(_device_id)) {
            _device_id = uuidv1();
            localStorage.setItem(ConfigEnum._DEVICE_ID, _device_id);
        }

        let params = {
            client_id: _APP_CLIENT_ID,
            client_secret: _APP_CLIENT_SECRET,
            grant_type: 'password',
            username: phone,
            password: password,
            IsEmulator: false,
            VersionCode: _WEB_VERSION,
            deviceId: _device_id,
            lat: location?.latitude,
            lng: location?.longitude
        };

        if (!stringIsNullOrEmpty(secondaryPassword)) {
            params['IsValidateSecondary'] = true;
            params['SecondaryPassword'] = secondaryPassword;
        }

        let formBody = createFormBody(params);

        var responseJson = await ApiEngine.post(Routes._LOGIN_URL, formBody, {
            headers: { 'User-Agent': navigator.userAgent.toLowerCase() }
        });

        if (responseJson && responseJson['role'] == _MEMBER_ROLE) {
            if (
                responseJson['secondary_password_method'] == undefined ||
                (responseJson['secondary_password_method'] ==
                    _LOGIN_METHOD._VERIFIED &&
                    !stringIsNullOrEmpty(responseJson.access_token))
            ) {
                await localStorage.setItem(ConfigEnum._USER_DATA, JSON.stringify(responseJson));
                await localStorage.setItem(ConfigEnum._BEARER_TOKEN, responseJson.access_token);

                dispatch({
                    type: AuthActionTypes._LOGIN_SUCCESS,
                    userData: responseJson
                });
            } else {
                return {
                    ...params,
                    method: responseJson['secondary_password_method'],
                    guid: responseJson.guid
                };
            }
        } else {
            let errorMsg =
                responseJson[ApiKey._API_MESSAGE_KEY] ??
                responseJson[ApiKey._API_ERROR_KEY] ??
                'INVALID_USERNAME_OR_PASSWORD';

            dispatch({
                type: AuthActionTypes._LOGIN_FAILED,
                message: i18n.t(errorMsg)
            });

            dispatch(
                showCustomDialog({
                    success: responseJson[ApiKey._API_SUCCESS_KEY],
                    content: i18n.t(errorMsg)
                })
            );
        }
    };
}

/// <summary>
/// Author : Saitama
/// </summary>
export const performLogout = () => {
    return async (dispatch, getState) => {
        let userData = getState().authState.userData;

        if (!isObjectEmpty(userData) && userData.guid) {
            try {
                let bearerToken = localStorage.getItem(ConfigEnum._BEARER_TOKEN)
                let params = {
                    Id: userData.guid
                };

                let formBody = createFormBody(params);
                await axios.post(Routes._LOGOUT_URL, formBody, {
                    headers: {
                        skipLoading: true,
                        'content-type': ApiKey._API_FORM_URLENCODED,
                        Authorization: 'Bearer ' + bearerToken
                    }
                });
            } catch (err) {
                // do nothing
            }
            finally {
                // window.location.replace(Pages._DEFAULT);
            }
        }

        localStorage.removeItem(ConfigEnum._BEARER_TOKEN);
        localStorage.removeItem(ConfigEnum._USER_DATA);
        localStorage.removeItem(ConfigEnum._EVENT_RANKING);
        localStorage.removeItem(ConfigEnum._USER_EVENT_RANKING);
        localStorage.removeItem(ConfigEnum._ADS_ID);
        localStorage.removeItem(ConfigEnum._HOME_BANNER_LAST_RETRIEVED);
        sessionStorage.removeItem(SessionKey._BOTTOM_MENU_ITEM);

        await dispatch({
            type: AuthActionTypes._LOGOUT
        });
    };
};

/// <summary>
/// Author : Saitama
/// </summary>
export function getMemberDetails(skipLoading = true) {
    return async (dispatch, getState) => {
        let guid = getState().authState.userData.guid;

        if (stringIsNullOrEmpty(guid)) {
            return;
        }

        const location = await getUserCurrentLocation();

        var responseJson = await ApiEngine.get(
            Routes._MEMBER_DETAILS
                .replace('{id}', guid)
                .replace('{androidVersion}', _WEB_VERSION)
                .replace('{latitude}', location?.latitude)
                .replace('{longitute}', location?.longitude),
            { headers: { skipLoading } }
        );
        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
            let errorMsg =
                responseJson[ApiKey._API_MESSAGE_KEY] ??
                responseJson[ApiKey._API_ERROR_KEY] ??
                'INTERNAL_SERVER_ERROR';

            dispatch(
                showCustomDialog({
                    success: responseJson[ApiKey._API_SUCCESS_KEY],
                    content: errorMsg
                })
            );
        } else {
            dispatch({
                type: AuthActionTypes._MEMBER_DETAILS_SUCCESS,
                userData: responseJson[ApiKey._API_DATA_KEY]
            });
        }
    };
}

/// <summary>
/// Author: Saitama
/// </summary>
export function performEditProfile(data, successCallback) {
    return async (dispatch, getState) => {
        let errorMsg = '';
        let memberId = getState().authState.userData.guid;

        let formData = new FormData();

        formData.append('alias', data.alias);
        formData.append('phoneNumber', getState().authState.userData.username);

        if (data.profileImage !== undefined) {
            formData.append('profileImage', data.profileImage);
        }

        var responseJson = await ApiEngine.put(
            Routes._EDIT_MEMBER.replace('{id}', memberId),
            formData,
            { 'Content-Type': 'multipart/form-data' }
        );

        if (!responseJson[ApiKey._API_SUCCESS_KEY]) {
            errorMsg =
                responseJson[ApiKey._API_MESSAGE_KEY] ??
                responseJson[ApiKey._API_ERROR_KEY] ??
                'INTERNAL_SERVER_ERROR';
        }

        if (responseJson[ApiKey._API_SUCCESS_KEY]) {
            dispatch(getMemberDetails());
        }

        dispatch(
            showCustomDialog({
                success: responseJson[ApiKey._API_SUCCESS_KEY],
                content: !responseJson[ApiKey._API_SUCCESS_KEY]
                    ? errorMsg
                    : 'EDIT_PROFILE_SUCCESS',
                onConfirm: () => {
                    if (responseJson[ApiKey._API_SUCCESS_KEY]) {
                        successCallback();
                    }
                },
                horizontalBtn: true
            })
        );
    };
}

/// <summary>
/// Author: Saitama
/// </summary>
export const updateUserData = (userData) => {
    return async (dispatch) => {
        await dispatch({
            type: AuthActionTypes._LOGIN_SUCCESS,
            userData: userData
        });
    };
};
